import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  cursor: pointer;
`;

export const LogoImage = styled.img`
  max-width: 180px;
  margin: 0 auto;
`;
