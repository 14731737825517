import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import Default from "./components/Default";
import Forgot from "./components/Forgot";
import Login from "./components/Login";
import Logout from "./components/Logout";

import { GlobalStyle } from "./globalStyles";
import { handleFirebaseAuth } from "./helpers/firebase-helper";
import useNewRelicScript from "./hooks/useNewRelicScript";

function App() {
  useNewRelicScript();
  useEffect(handleFirebaseAuth, []);

  return (
    <>
      <GlobalStyle />
      <Switch>
        <Route path="/" exact component={Default} />
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={Logout} />
        <Route path="/forgot" exact component={Forgot} />
      </Switch>
    </>
  );
}

export default App;
