import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import SegfyLogo from "../Login/SegfyLogo";

const Default: React.FC = () => {
  let history = useHistory();

  useEffect(() => {
    const managementSoftwareUrl =
      process.env.REACT_APP_MANAGEMENT_SOFTWARE_SEGFY ?? "https://app.segfy.com";
    history.push(`/login?callback=${managementSoftwareUrl}`);
  }, [history]);

  return <SegfyLogo />;
};

export default Default;
