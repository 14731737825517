import firebase from 'firebase/app';
import 'firebase/auth';
import { FormHandles } from '@unform/core';

import { ILoginModel } from '../../model/login';

import { methods } from '.';
import { validateForm } from './validator';
import { redirect, getCallbackUrl } from '../../helpers/navigation-helper';

export const onFormSubmit = (
  login: ILoginModel,
  formRef: React.RefObject<FormHandles>,
) => {
  const onSuccessfulSubmit = async () => {
    firebase
      .auth()
      .sendPasswordResetEmail(login.email)
      .then((success) => {
        methods.setLoading(false);
        redirect(`/login?passReset=1&callback=${getCallbackUrl()}`);
      })
      .catch((error) => {
        methods.setLoading(false);

        if (error.code === "auth/user-not-found") {
          methods.setErrorMessage("Usuário não encontrado.");
        } else {
          methods.setErrorMessage(error.message);
        }
      });
  };

  const onUnsuccessfulSubmit = () => {
    methods.setLoading(false);
  };

  validateForm({ data: login, formRef, onSuccessfulSubmit, onUnsuccessfulSubmit });
};
