import { SegfyButtonType } from './types';

export function getClassName(type?: SegfyButtonType) {
  switch (type ?? SegfyButtonType.primary) {
    case SegfyButtonType.primary:
      return 'primary';
    case SegfyButtonType.secondary:
      return 'secondary';
  }
}
