import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

import { InputProps } from './types';
import { SegfyInputProps } from './types';
import { Container, Error } from './styles';

const SegfyInput: React.FC<SegfyInputProps & InputProps> = ({
  name,
  label,
  required = false,
  ...allProps
}: SegfyInputProps) => {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container>
      <span>
        {label} {required ? '*' : ''}
      </span>
      <input {...allProps} ref={inputRef} defaultValue={defaultValue} />
      <Error>{error}</Error>
    </Container>
  );
};

export default SegfyInput;
