import React from 'react';
import Image from '../../../images/Illsutration@2x.png';

import { Container, IllustrationImage } from './styles';

const Illustration: React.FC = () => {
  return (
    <Container>
      <IllustrationImage src={Image} alt="Illustration" />
    </Container>
  );
};

export default Illustration;
