import React, { useEffect } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { writeLog } from "../../helpers/log-helper";
import SSOApi from "../../services/sso-api";

async function logout() {
  writeLog("posting logout");
  const api = new SSOApi();
  api.logout();
}

const Logout: React.FC = () => {

  useEffect(() => {
    firebase
      .auth()
      .signOut()
      .then(() => logout());
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Saindo...
    </div>
  );
};

export default Logout;
