import styled, { createGlobalStyle } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

export const GlobalStyle = createGlobalStyle` 
  :root {
    --segfy-color-black: #807973;
    --segfy-color-darkgrey: #b1aba6;
    --segfy-color-grey: #dedede;
    --segfy-color-lightgrey: #f1f3f4;
    --segfy-color-accent: #faf7f2;
    --segfy-color-red: #d25032;
    --segfy-color-lightred: #f2937e;
    --segfy-color-orange: #f4ad4b;
    --segfy-color-lime: #e2ed8d;
    --segfy-color-green: #69c782;
    --segfy-color-lightgreen: #c8f2d3;
    --segfy-color-cyan: #8deddb;
    --segfy-color-lightblue: #b1dff0;
    --segfy-color-white: #ffffff;
  }
  
  body {
    margin: 0;
    font-family: 'Open Sans';
    font-size: 12px;
    color: var(--segfy-color-black);
    height: 100vh;
  }

  #rootLogin {
    height: 100%;
  }
`;

export const Error = styled.div`
  color: var(--segfy-color-red);
  animation: changeLetter 3s linear infinite alternate;
  transition: width 0.25s;
  margin-left: 20px;
  height: 18px;
  max-width: 310px;
`;

export const Loading = styled(CircularProgress)`
    color: var(--segfy-color-orange) !important;
    display: block !important;
    margin-left: auto;
    margin-right: auto;
`;
