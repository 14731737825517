import * as Yup from 'yup';
import { validateFormAsync } from '../../helpers/validator-helper';
import { TValidateFormsArgs } from '../../helpers/validator-helper-types';

export const MSG_REQUIRED = 'Campo obrigatório';

const validationSchema = Yup.object().shape({
  email: Yup.string().required(MSG_REQUIRED).email('E-mail inválido'),
});

export function validateForm(args: TValidateFormsArgs) {
  return validateFormAsync({ ...args, validationSchema });
}
