import { datadogLogs } from '@datadog/browser-logs';


export function writeLog(text: string) {
  if (process.env.REACT_APP_LOG_ENV !== "prod") {
    console.log(text);
  }
}

export function writeWarn(text: string) {
  if (process.env.REACT_APP_LOG_ENV !== "prod") {
    console.warn(text);
  }
}

export function writeError(text: string) {
  datadogLogs.logger.error(text)
  if (process.env.REACT_APP_LOG_ENV !== "prod") {
    console.error(text);
  }
}
