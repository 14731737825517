import React from "react";
import { useLocation } from "react-router-dom";

import { Error } from "../../globalStyles";
import FormBase from "../shared/FormBase";
// import ButtonGoogle from './ButtonGoogle';
import LoginForm from "./LoginForm";

// import { InterssectionText } from './styles';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Login: React.FC = () => {
  const query = useQuery();
  const isPassReset = query.get("passReset");

  return (
    <FormBase>
      {/* <ButtonGoogle /> */}
      {/* <InterssectionText>ou</InterssectionText> */}
      <LoginForm />
      {isPassReset && (
        <Error>
          Um e-mail foi enviado com as instruções para redefinir sua senha.
        </Error>
      )}
    </FormBase>
  );
};

export default Login;
