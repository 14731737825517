import React, { useEffect, useRef, useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import SegfyButton from "../../shared/SegfyButton";
import SegfyInput from "../../shared/SegfyInput";
import { SegfyButtonType } from "../../shared/SegfyButton/types";
import { ILoginModel } from "../../../model/login";
import { Error as ErrorDisplay, Loading } from "../../../globalStyles";

import { Container, Field, ForgotPass } from "./styles";
import { onFormSubmit } from "./actions";
import { getCallbackUrl } from "../../../helpers/navigation-helper";

export const methods = {
  setErrorMessage: (msg: string) => {},
  setLoading: (isLoading: boolean) => {},
};

const LoginForm: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const setErrorRef = useRef(setErrorMessage);
  const setLoadingRef = useRef(setLoading);

  useEffect(() => {
    formRef.current?.setData({});
    methods.setErrorMessage = setErrorRef.current;
    methods.setLoading = setLoadingRef.current;
  }, []);

  const onSubmit = (login: ILoginModel) => {
    setLoading(true);
    setErrorMessage("");

    onFormSubmit(login, formRef);
  };

  datadogRum.addRumGlobalContext("Login", {
    loginEmail: formRef.current?.getFieldValue("email"),
  });

  return (
    <Container>
      <Form onSubmit={onSubmit} ref={formRef}>
        <Field>
          {errorMessage && <ErrorDisplay>{errorMessage}</ErrorDisplay>}
        </Field>
        <Field>
          <SegfyInput label="E-mail" name="email" />
        </Field>
        <Field>
          <SegfyInput label="Senha" type="password" name="password" />
        </Field>
        <Field>
          <ForgotPass href={`/forgot?callback=${getCallbackUrl()}`}>
            Esqueci a senha
          </ForgotPass>
        </Field>
        <Field>
          {!isLoading ? (
            <SegfyButton
              type="submit"
              width={320}
              label="Entrar"
              buttonType={SegfyButtonType.primary}
              newIdentity={true}
            />
          ) : (
            <Loading />
          )}
        </Field>
      </Form>
    </Container>
  );
};

export default LoginForm;
