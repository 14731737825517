import firebase from "firebase/app";
import "firebase/auth";

import { writeError, writeLog } from "./log-helper";
import {
  redirectToCallbackUrl,
} from "./navigation-helper";
import SSOApi from "../services/sso-api";

export default function initFirebase() {
  firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  });

  firebase.auth().languageCode = "pt";
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
}

export function addCookieAndRedirect(idToken: string) {
  writeLog(process.env.REACT_APP_FIREBASE_APIKEY ?? "no apikey");
  writeLog(process.env.REACT_APP_FIREBASE_AUTHDOMAIN ?? "no auth domain");
  const api = new SSOApi();
  api
    .login(idToken)
    .then(() => {
      redirectToCallbackUrl();
    })
    .catch((err) => {
      writeError(err);
      alert("Não foi possível fazer o login.");
    });
}

export function handleFirebaseAuth() {
  firebase.auth().onAuthStateChanged((user) => {
    if (!user) {
      return;
    }

    writeLog("onAuthStateChanged. User OK");

    user.getIdToken().then((idToken) => {
      addCookieAndRedirect(idToken);
    });
  });
}
