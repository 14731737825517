import * as Yup from 'yup';
import { TValidateFormsArgs } from './validator-helper-types';

export const MSG_REQUIRED = 'Campo obrigatório';

export type TValidateFormsSchemaArgs = {
  validationSchema: Yup.ObjectSchema<any>;
};

export async function validateFormAsync({
  formRef,
  validationSchema,
  data,
  onSuccessfulSubmit,
  onUnsuccessfulSubmit
}: TValidateFormsArgs & TValidateFormsSchemaArgs) {
  if (!formRef?.current) throw new Error('A valid form is required.');
  try {
    formRef.current.setErrors({});

    await validationSchema.validate(data, {
      abortEarly: false,
    });

    await onSuccessfulSubmit();
  } catch (err) {
    const validationErrors: any = {};

    if (err instanceof Yup.ValidationError) {
      err.inner.forEach((error) => {
        if (!error.path) return;
        const key: keyof any = error.path;
        validationErrors[key] = error.message ?? '';
      });
      formRef.current.setErrors(validationErrors);
    } else {
      console.error(err);
    }
    
    onUnsuccessfulSubmit();
  }
}
