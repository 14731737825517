import React from 'react';
import logo from '../../../images/segfy-logo.png';

import { Container, LogoImage } from './styles';

import { redirect, getCallbackUrl } from '../../../helpers/navigation-helper';

const SegfyLogo: React.FC = () => {

  const handleClick = () => {
    redirect(`/login?callback=${getCallbackUrl()}`);
  }
  
  return (
    <Container onClick={handleClick}>
      <LogoImage src={logo} alt="Logo Segfy" />
    </Container>
  );
};

export default SegfyLogo;
