import styled from "styled-components";

export const Container = styled.div``;

export const ForgotPass = styled.a`
  color: #d25032;
  text-decoration: underline;
  font-size: 11px;
  text-align: end;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -16px;
}
`;

export const Field = styled.div`
  margin-bottom: 10px;
`;
