import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
`;

export const IllustrationImage = styled.img`
  max-width: 320px;
  margin: 0 auto;
`;
