import styled from "styled-components";

type TButtonProps = {
  width: number;
  newIdentity?: boolean;
};

export const Button = styled.button<TButtonProps>`
  cursor: pointer;
  border-radius: 15px;
  width: ${({ width }) => `${width}px`};
  height: 30px;
  transition-duration: 0.2s;
  border: ${({ newIdentity }) =>
    newIdentity ? "#F26326 solid 1px" : "#d25032 solid 1px"};
  font-size: 12px;
  min-width: 30px;
  outline: 0;

  &.primary {
    background-color: ${({ newIdentity }) =>
      newIdentity ? "#F26326" : "#d25032"};
    color: #fff;
  }

  &.primary:hover:enabled {
    background-color: ${({ newIdentity }) =>
      newIdentity ? "#F99E1B" : "#f2937e"};
    border: ${({ newIdentity }) =>
      newIdentity ? "#F99E1B solid 1px" : "#f2937e solid 1px"};
  }

  &.secondary {
    background-color: #fff;
    color: #d25032;
  }

  &.secondary:hover:enabled {
    background-color: #f5e2de;
  }

  &:disabled {
    background-color: #dedede;
    border: #dedede solid 1px;
    color: #807973;
  }
`;
