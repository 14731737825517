import firebase from 'firebase/app';
import 'firebase/auth';
import { FormHandles } from '@unform/core';

import { methods } from '.';
import { ILoginModel } from '../../../model/login';
import { validateForm } from './validator';
import { writeError, writeLog } from '../../../helpers/log-helper';

export const onFormSubmit = (
  login: ILoginModel,
  formRef: React.RefObject<FormHandles>
) => {
  const onSuccessfulSubmit = async () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(login.email, login.password)
      .then((user) => {
        writeLog('firebase auth signInWithEmailAndPassword - success');
      })
      .catch((error) => {
        methods.setLoading(false);
        
        if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
          methods.setErrorMessage('E-mail ou senha inválidos');
        } else {
          methods.setErrorMessage(error.message);
        }

        var errorCode = error.code;
        var errorMessage = error.message;
        writeError(`${errorCode} - ${errorMessage}`);
      });
  };

  const onUnsuccessfulSubmit = () => {
    methods.setLoading(false);
  }

  validateForm({ data: login, formRef, onSuccessfulSubmit, onUnsuccessfulSubmit });
};
