import { useEffect } from 'react';

const useNewRelicScript = () => {
  useEffect(() => {
    const currentSegfyEnv = process.env.REACT_APP_SEGFY_ENVIRONMENT || 'local';
    const validEnvs = ['dev', 'qa', 'prod'];
    if (!validEnvs.includes(currentSegfyEnv)) {
      console.warn(
        'REACT_APP_SEGFY_ENVIRONMENT not loaded, will not add newRelic'
      );
      return () => {};
    }

    
    const script = document.createElement('script');
    script.src = `newrelic/${currentSegfyEnv}.js`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useNewRelicScript;
