import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";

import initFirebase from "./helpers/firebase-helper";

import App from "./App";

const env = process.env.REACT_APP_SEGFY_ENVIRONMENT;
const clientToken =
  process.env.REACT_APP_DATADOG_SSO_CLIENTTOKEN || "client token is missing";
const applicationId =
  process.env.REACT_APP_DATADOG_SSO_APPLICATIONID ||
  "application id is missing";

datadogRum.init({
  applicationId,
  clientToken,
  site: "datadoghq.com",
  service: `sso-${env}`,
  env: env,
  // Specify a version number to identify the deployed version of your application in Datadog
  // version: '1.0.0',
  sampleRate: 100,
  trackInteractions: true,
  defaultPrivacyLevel: "mask",
  allowedTracingOrigins: [/https:\/\/login.segfy.\w+/],
});

datadogRum.startSessionReplayRecording();

datadogLogs.init({
  clientToken,
  site: "datadoghq.com",
  service: `sso-${env}`,
  env: env,
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

datadogLogs.logger.info("Setup SSO", { env: env });

initFirebase();

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('rootLogin')
);
