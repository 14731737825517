import React, { useEffect, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";

import FormBase from "../shared/FormBase";
import SegfyButton from "../shared/SegfyButton";
import SegfyInput from "../shared/SegfyInput";
import { ILoginModel } from "../../model/login";
import { SegfyButtonType } from "../shared/SegfyButton/types";

import { onFormSubmit } from "./actions";
import { Field } from "./styles";
import { Error, Loading } from "../../globalStyles";

export const methods = {
  setErrorMessage: (msg: string) => {},
  setLoading: (state: boolean) => {},
};

const Forgot: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const formRef = useRef<FormHandles>(null);

  const setErrorRef = useRef(setErrorMessage);
  const setLoadingRef = useRef(setLoading);

  useEffect(() => {
    formRef.current?.setData({});
    methods.setErrorMessage = setErrorRef.current;
    methods.setLoading = setLoadingRef.current;
  }, []);

  const onSubmit = (login: ILoginModel) => {
    setLoading(true);
    setErrorMessage("");

    onFormSubmit(login, formRef);
  };

  return (
    <FormBase>
      <Form onSubmit={onSubmit} ref={formRef}>
        <Field>{errorMessage && <Error>{errorMessage}</Error>}</Field>
        <Field>
          <SegfyInput label="E-mail cadastrado" name="email" />
        </Field>
        <Field>
          {!isLoading ? (
            <SegfyButton
              width={320}
              label="Recuperar senha"
              buttonType={SegfyButtonType.primary}
              newIdentity={true}
            />
          ) : (
            <Loading />
          )}
        </Field>
      </Form>
    </FormBase>
  );
};

export default Forgot;
