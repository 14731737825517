import { ReactElement } from "react";

export enum SegfyButtonType {
  primary,
  secondary,
}

export type ButtonProps = JSX.IntrinsicElements["button"];

export type SegfyButtonProps = {
  label: string | ReactElement;
  buttonType?: SegfyButtonType;
  width?: number;
  newIdentity?: boolean;
};
