import React from 'react';
import Illustration from '../../Login/Illustration';
import SegfyLogo from '../../Login/SegfyLogo';

import { OuterContainer, InnerContainer } from './styles';

const FormBase: React.FC = ({ children }: any) => {
  return (
    <OuterContainer>
      <InnerContainer>
        <SegfyLogo />
        <Illustration />
        {children}
      </InnerContainer>
    </OuterContainer>
  );
};

export default FormBase;
