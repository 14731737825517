import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-gap: 0px;
`;

export const Container = styled(Grid)`
  & span {
    color: #807973;
    font-size: 12px;
    margin-left: 20px;
  }

  & input {
    border: 1px solid #ccc;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 5px;
    outline: 0;
    height: 26px;
  }

  & input::placeholder {
    color: #b1aba6;
  }

  & input:focus {
    border: 1px solid #666666;
    transition: ease-in-out, width 0.35s ease-in-out;
  }
`;

export const Error = styled.div`
  color: var(--segfy-color-red);
  animation: changeLetter 3s linear infinite alternate;
  transition: width 0.25s;
  margin-left: 20px;
  height: 18px;
`;
