import axios, { AxiosError } from "axios";
import { writeError, writeLog, writeWarn } from "../helpers/log-helper";
import { getCallbackUrl, redirect } from "../helpers/navigation-helper";

class SSOApi {
  private api = axios.create({ baseURL: process.env.REACT_APP_SSO_API });

  private getErrors(error: AxiosError) {
    if (error.response) {
      // Request made and server responded
      writeError(error.response.data);
      writeError(error.response.status.toString());
      writeError(error.response.headers);
    } else if (error.request) {
      // The request was made but no response was received
      writeError(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      writeError(`Error ${error.message}`);
    }
  }

  async login(idToken: string) {
    try {
      writeLog(process.env.REACT_APP_SSO_API ?? "no api");
      writeLog(process.env.REACT_APP_SSO_API_LOGIN ?? "no login");
      const path = process.env.REACT_APP_SSO_API_LOGIN || "/sso-login-dev";
      const response = await this.api.post(
        path,
        { idToken: idToken },
        { withCredentials: true }
      );
      if (response.status >= 200 && response.status < 300) {
        writeLog("SessionToken finished. Redirect!");
      } else {
        throw new Error(`API request status: ${response.status}`);
      }
    } catch (error) {
      this.getErrors(error);
      throw new Error("Could not add cookie!");
    }
  }

  logout() {
    const callbackUrl = getCallbackUrl();
    const path = process.env.REACT_APP_SSO_API_LOGOUT || "/sso-logout-dev";
    const response = this.api
      .post(path, {}, { withCredentials: true })
      .then((data) => {

        if (callbackUrl) {
          writeLog("redirecting to login...");
          redirect("/login?callback=" + callbackUrl);
        } else {
          writeWarn("not redirecting, no callback url");
          redirect("/login");
        }
      })
      .catch((error) => {
        this.getErrors(error);
        throw new Error("Could not logout!");
      });

    return response;
  }
}

export default SSOApi;
