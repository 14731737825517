function getUrlParam(name: string) {
  return new URLSearchParams(window.location.search).get(name);
}

export function getCallbackUrl() {
  return getUrlParam("callback");
}

export function redirectToCallbackUrl() {
  var url = getCallbackUrl();

  if (url) {
    redirect(url);
  } else if (process.env.REACT_APP_DEFAULT_REDIRECT) {
    redirect(process.env.REACT_APP_DEFAULT_REDIRECT);
  }
}

export function redirect(url: string) {
  window.location.href = url;
}
