import React, { FunctionComponent } from 'react';
import { getClassName } from './actions';
import { Button } from './styles';
import { ButtonProps, SegfyButtonProps } from './types';

const SegfyButton: FunctionComponent<SegfyButtonProps & ButtonProps> = ({
  label,
  buttonType,
  width = 100,
  ...allProps
}: SegfyButtonProps) => {
  return (
    <Button width={width} className={getClassName(buttonType)} {...allProps}>
      {label}
    </Button>
  );
};

export default SegfyButton;
